import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/styles/styleLoader";
import { getCurrentLanguage, lang } from "./helpers/language";
import { updateDirection } from "./helpers/direction";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const loading = () => (
	<div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login"));

class App extends Component {
	// require('dotenv').config()

	constructor(props) {
		super(props);
		getCurrentLanguage();
		updateDirection();
	}

	render() {
		return (
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_SITE_KEY}
			>
				<BrowserRouter>
					<React.Suspense fallback={loading()}>
						<Switch>
							{/*Routes without login*/}
							<Route
								exact
								path="/login"
								name={lang("nav.Login")}
								render={props => <Login {...props} />}
							/>
							<Route
								exact
								path="/login/superadmin"
								name={lang("nav.Login")}
								render={props => <Login {...props} />}
							/>

							{/*Routes and sub routes with login (routes.js)*/}
							<Route
								path="/"
								name="Home"
								render={props => <DefaultLayout {...props} />}
							/>
						</Switch>
					</React.Suspense>
				</BrowserRouter>
			</GoogleReCaptchaProvider>
		);
	}
}

export default App;
